interface Props {
  imagePath: string
  text: string
}

/**
 * 個人投資家の皆さまへに使用されるカード、文言つき
 * @param {string} imagePath
 * @param {string} text
 */
/** */
export const CardIllust = ({ imagePath, text }: Props) => {
  return (
    <li className="pc:w-[263px] flex-row items-center flex pc:block text-center px-6 py-3 pc:pt-6 pc:pb-8 pc:h-[200px] border border-border10">
      <div className="w-[90px] h-[90px] pc:w-[97px] pc:h-[97px] pc:m-auto">
        <img src={imagePath} alt="" />
      </div>
      <p
        className="font-bold leading-tight text-left pc:text-center text-md pc:mt-1 ml-3 pc:ml-0"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </li>
  )
}
