import React, { ReactNode } from 'react'
import { css } from '@emotion/react'
import { LinkBase } from '../Link/LinkBase'
import { colorList } from '../../styles/color'

interface Props {
  children: ReactNode
  url: string
}

export const styleButtonPrimary = css`
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  min-width: 240px;
  padding: 24px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  position: relative;
  background-color: ${colorList.primary};
  overflow: hidden;
  span {
    z-index: 3;
    transition: 0.6s;
  }
  &::before {
    --tilt: 1.2rem;
    background-color: ${colorList.text10};
    clip-path: polygon(0 0, calc(100% - var(--tilt)) 0, 100% 100%, 0 100%);
    content: '';
    display: block;
    height: 100%;
    left: calc(-100% - var(--tilt));
    position: absolute;
    top: 0;
    transform: translateX(var(--progress, 0));
    transition: transform 0.2s ease;
    width: calc(100% + var(--tilt));
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 24px;
    height: 1px;
    background-color: white;
    transition: 0.3s;
    z-index: 3;
  }
  &:hover {
    &::before {
      --progress: 100%;
    }
    &::after {
      transform: translateX(100%);
    }
  }
`

/**
 * ボタン
 * @param {string} label 文言
 * @param {string} url url
 */
/** */
export const ButtonPrimary = ({ children, url }: Props) => {
  return (
    <LinkBase css={styleButtonPrimary} href={url}>
      <span>{children}</span>
    </LinkBase>
  )
}
